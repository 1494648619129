import React from 'react'
import { graphql } from 'gatsby'
// import Link from "@components/Link"
// import { Button } from "@material-ui/core"
import Layout from '@components/layout'
import SEO from '@components/seo'
// import { rhythm } from "@utils/typography"
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import Camera from '@material-ui/icons/Camera'
import Palette from '@material-ui/icons/Palette'
import Favorite from '@material-ui/icons/Favorite'

import Parallax from '@components/Parallax/Parallax'

// import Button from "@components/CustomButtons/Button"
import NavPills from '@components/NavPills/NavPills.js'

import profile from '@assets/img/minzu.png'

import studio1 from '@assets/img/examples/3L7A8614.jpg'
import studio2 from '@assets/img/examples/3L7A7272.jpg'
import studio3 from '@assets/img/examples/20210116104041.jpg'
import studio4 from '@assets/img/examples/3L7A8266.jpg'
import featured1 from '@assets/img/examples/3L7A9490.jpg'
import work1 from '@assets/img/examples/3L7A9490.jpg'
import work2 from '@assets/img/examples/3L7A9490.jpg'
import work3 from '@assets/img/examples/3L7A9490.jpg'
import work4 from '@assets/img/examples/3L7A9490.jpg'

import styles from '@assets/jss/views/profilePage.js'
const useStyles = makeStyles({
  ...styles,
  imageTile: {
    marginBottom: '5px',
  },
})

const About = props => {
  const siteTitle = props.data.site.siteMetadata.title
  const classes = useStyles()
  // const { ...rest } = props;
  const imageClasses = clsx(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const navImageClasses = clsx(classes.imgFluid, classes.imageTile)

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="All photos" />
      <Parallax
        filter
        image={require('@assets/img/landing-bg.jpg')}
        style={{ height: '360px' }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}></div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div
        className={clsx(classes.main, classes.mainRaised)}
        style={{ marginTop: '-80px' }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <div className={classes.profile}>
                <div style={{ height: '260px' }}>
                  <img src={profile} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h2 className={classes.title}>小舞花舞蹈学院</h2>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.description}>
            <p>
              小舞花舞蹈学院创立于2015年。长期以来，学院坚持以专业舞蹈教学为主的理念，立足舞蹈普及及教育的平台，
              加强多民族舞蹈文化的交流互动，弘扬民族民间舞蹈艺术，
              致力慈善公益事业。学院的学生无数次参加各类演出和比赛，硕果累累。因为我们拥有一批优秀的教师团队。我们的教师来自于专业的舞蹈音乐学院，
              她们都拥有丰富的舞台演出及教学经验。
            </p>
            <p>
              Flower Academy of Dance Founded in 2015. We has long adhered to
              the concept of professional dance teaching based on the platform
              dance popularization education, strengthen the exchange and
              interaction a multi-ethnic dance culture, carry forward the
              national folk dance Art, is committed to charitable public welfare
              undertakings. Students of the College have participated various
              performances and competitions on numerious occasions, with
              fruitful results. Because we have an excellent team of teachers.
              Our teachers come from professional dance and music colleges, all
              of whom have a wealth of stage performances and teaching
              experience
            </p>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={9} className={classes.navWrapper}>
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: '场地',
                    tabIcon: Camera,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            alt="..."
                            src={studio1}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={studio2}
                            className={navImageClasses}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            alt="..."
                            src={studio3}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={studio4}
                            className={navImageClasses}
                          />
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: '成就',
                    tabIcon: Palette,
                    tabContent: (
                      <GridContainer justify="center" style={{ width: '100%' }}>
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            alt="..."
                            src={work1}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={work2}
                            className={navImageClasses}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            alt="..."
                            src={work4}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={work3}
                            className={navImageClasses}
                          />
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                  {
                    tabButton: '精选',
                    tabIcon: Favorite,
                    tabContent: (
                      <GridContainer justify="center" style={{ width: '100%' }}>
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            alt="..."
                            src={featured1}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={featured1}
                            className={navImageClasses}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <img
                            alt="..."
                            src={featured1}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={featured1}
                            className={navImageClasses}
                          />
                        </GridItem>
                      </GridContainer>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
